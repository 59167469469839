/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';
import './layout.css';
import useDimensions from 'react-use-dimensions';

const Layout = ({
  children,
  headerImage,
  sidebar,
  sidebarSide = 'right',
  dominantColour = '#3c484e',
  textColour = '#fff',
}) => {
  const [ref, { height, width }] = useDimensions();
  const isMobileLayout = Boolean(width <= 800);

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  const showSidebarOnSide = Boolean(sidebar && !isMobileLayout);
  const flexDirection = sidebarSide === 'right' ? 'row' : 'row-reverse';
  return (
    <>
      <Header
        siteCoverImage={headerImage}
        isMobileLayout={isMobileLayout}
        dominantColour={dominantColour}
        textColour={textColour}
      />
      <div
        ref={ref}
        style={{
          minHeight: '100%',
          backgroundColor: '#eee',
          display: showSidebarOnSide ? 'flex' : undefined,
          flexDirection: showSidebarOnSide ? flexDirection : undefined,
          justifyContent: 'space-evenly',
        }}
      >
        <main
          id="main"
          className="main-content"
          style={{
            margin: `0 auto`,
            maxWidth: 1200,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
            minHeight: '100%',
            backgroundColor: '#eee',
            flexGrow: 1,
          }}
        >
          {children}
        </main>
        {sidebar}
      </div>
      <Footer isMobileLayout={isMobileLayout} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
